import React, {FC} from 'react';
import Layout from '../templates/Layout';
import InstallRoot from '../components/Install';

const Install: FC<void> = () => {
    return (
        <Layout>
            <InstallRoot />
        </Layout>
    );
};

export default Install;
